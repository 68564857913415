module.exports = {
  author: 'DirectMailers',
  background: '#FFFFFF',
  description: '',
  language: 'en',
  ogLanguage: 'en_US',
  shortName: 'YAPA',
  siteUrl: 'https://youarepreapproved.com/',
  theme: '#0096D7',
  title: 'YouArePreApproved',
  icon: 'src/base/assets/favicons/icon.png',
};
