const config = require('./utils/siteConfig');
const archConfig = require('./utils/archConfig');

require('dotenv').config({ path: `.env.${process.env.NODE_ENV}` });

const getConfig = () => {
  if (process.env.GATSBY_CLIENT_NAME === 'home-arch-portfolio') {
    return archConfig;
  }

  return config;
};

module.exports = {
  plugins: [
    'gatsby-plugin-postcss',
    'gatsby-plugin-preact',
    'gatsby-plugin-react-helmet',
    'gatsby-transformer-sharp',
    {
      options: {
        accessToken: process.env.GATSBY_SB_ACCESS_TOKEN,
        homeSlug: 'home',
        version: process.env.NODE_ENV === 'production' ? 'published' : 'draft',
      },
      resolve: 'gatsby-source-storyblok',
    },
    {
      options: {
        headers: {
          '/*': [
            `Access-Control-Allow-Origin: https://yapaapi.directmailers.com/api/LookUpFindIt`,
            `X-XSS-Protection: 1; mode=block`,
            `X-Content-Type-Options: nosniff`,
            `Referrer-Policy: no-referrer`,
            `Expect-CT: max-age=604800`,
            `Feature-Policy: accelerometer 'none'; camera 'none'; geolocation 'none'; gyroscope 'none'; magnetometer 'none'; microphone 'none'; payment 'none'; usb 'none'`,
            `Strict-Transport-Security: max-age=31536000; includeSubDomains; preload`,
          ],
        },
        mergeSecurityHeaders: false,
      },
      resolve: 'gatsby-plugin-netlify',
    },
    {
      options: {
        background_color: getConfig().background,
        display: 'standalone',
        icon: getConfig().icon,
        name: getConfig().title,
        short_name: getConfig().shortName,
        start_url: '/',
        theme_color: getConfig().theme,
      },
      resolve: 'gatsby-plugin-manifest',
    },
    {
      options: {
        defaultQuality: 100,
        stripMetadata: true,
      },
      resolve: 'gatsby-plugin-sharp',
    },
    {
      options: {
        prettier: true,
        svgo: true,
        svgoConfig: {
          plugins: {
            cleanupIDs: true,
            removeViewBox: true,
          },
        },
      },
      resolve: 'gatsby-plugin-svgr',
    },
    {
      options: {
        name: 'images',
        path: `${__dirname}/src/base/assets/images/`,
      },
      resolve: 'gatsby-source-filesystem',
    },
    {
      options: {
        name: `data`,
        path: `${__dirname}/src/base/assets/data/`,
      },
      resolve: `gatsby-source-filesystem`,
    },
    `gatsby-transformer-csv`,
  ],
  siteMetadata: {
    author: getConfig().author,
    description: getConfig().description,
    language: getConfig().language,
    siteUrl: getConfig().siteUrl,
    theme: getConfig().theme,
    title: getConfig().title,
  },
};
