module.exports = {
  author: 'ArcHome',
  background: '#FFFFFF',
  description: '',
  language: 'en',
  ogLanguage: 'en_US',
  shortName: 'ARC',
  siteUrl: 'https://myoffer.archomedirect.com/',
  theme: '#0096D7',
  title: 'ArcHome',
  icon: 'src/base/assets/favicons/arc_logo.png',
};
